<template>
  <v-container fluid>
    <v-layout column align-center>
      <v-flex xs12>
        <p>Nom</p>
        <p class="text-xs-center">
            <input v-model="name" placeholder="Veuillez entrer un nom ..." />
        </p>
        <p>L'url d'un document Word (.doc, .docx, .odt)</p>
        <p class="text-xs-center">
            <input v-model="urlDocument" placeholder="Veuillez entrer l'url ..." />
        </p>
      </v-flex>
      <v-flex xs12>
        <v-btn
          :disabled="!isValid() || loading"
          color="primary"
          @click="send()"
          :loading="loading"
        >
          Envoyer
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'new',
  data: () => ({
    loading: false,
    urlDocument: '',
    name: '',
  }),
  methods: {
    isValid() {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(this.urlDocument);
    },
    send() {
      this.loading = true;
      this.$store.dispatch('documents/convertDocument', {
        name: this.name,
        fileUrl: this.urlDocument,
      }).then(() => {
        this.loading = false;
        this.setSnack('Le document a bien été généré.');
        this.$router.push('/list');
      }).catch(() => {
        this.loading = false;
        this.setSnack('Erreur pendant la convertion du document.');
        this.$router.push('/list');
      });
    },
    ...mapMutations({
      setSnack: 'snackbar/setSnack',
    }),
  },
};
</script>
